.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


fieldset.MuiFormControl-root {
  margin-bottom: 20px;
}

*[draggable] {
  cursor: move !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.filepond--credits {
  display:none;
}



.component-selected {
  position:"relative";
}
.component-selected::after {
  content: ' ';
  position:"absolute";
  border : '1px solid red';
  width:"100%";
  height:"100%";
  left:0;
  top:0;
  display:block;
  pointer-events: none;
}


.craftjs-renderer {
  opacity: 1;
  transition-delay: 0.5s;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


/*Page Navigation Link
nav {    
  display: block;
  text-align: center;
}
nav ul {
  margin: 0;
  padding:0;
  list-style: none;
}*/
.pageNavLink a {
  display:block; 
  background: #111; 
  color:#fff; 
  text-decoration: none;
  padding: .8em 1.8em;
  font-size: 80%;letter-spacing: 2px;
  position: relative;
}
.pageNavLink{  
  vertical-align: top; 
  display: inline-block;
  /*border-radius:6px;*/
}
.pageNavLink li{position: relative;}
.pageNavLink > li { 
  float:left; 
  /*border-bottom: 4px #aaa solid; */
  margin-right: 1px; 
} 
.pageNavLink > li > a { 
  margin-bottom:1px;
}
/*.pageNavLink > li:hover , .pageNavLink > li:hover >a{  border-bottom-color:orange;}*/
.pageNavLink li:hover > a { color:orange; }
/*.pageNavLink > li:first-child  { border-radius: 4px 0 0 4px;} 
.pageNavLink > li:first-child>a{border-radius: 4px 0 0 0;}*/
.pageNavLink > li:last-child  { 
 /* border-radius: 0 0 4px 0; */
  margin-right: 0;
} 
.pageNavLink > li:last-child >a{border-radius: 0 4px 0 0; }
.pageNavLink li li a { margin-top:1px}



  .pageNavLink li a:first-child:nth-last-child(2):before { 
   content:""; 
   position: absolute; 
   height:0; 
   width: 0; 
   /*border: 5px solid transparent; */
   top: 50% ;
   right:5px;  
 }
 
 
 
 
 
 /* submenu positioning*/
.pageNavLink ul {
position: absolute;
white-space: nowrap;
z-index: 1;
left: -99999em;
}
.pageNavLink > li:hover > ul {
left: auto;
padding-top: 5px  ;
min-width: 100%;
}
/*Separator*/
/*.pageNavLink > li li ul {  border-left:1px solid #fff;}*/ 


.pageNavLink > li li:hover > ul { 
/* margin-left: 1px */
left: 100%;
top: -1px;
}
/* arrow hover styling */
.pageNavLink > li > a:first-child:nth-last-child(2):before { 
border-top-color: #aaa; 
}
.pageNavLink > li:hover > a:first-child:nth-last-child(2):before {
border: 5px solid transparent; 
border-bottom-color: orange; 
margin-top:-5px
}
.pageNavLink li li > a:first-child:nth-last-child(2):before {  
border-left-color: #aaa; 
margin-top: -5px
}
.pageNavLink li li:hover > a:first-child:nth-last-child(2):before {
border: 5px solid transparent; 
border-right-color: orange;
right: 10px; 
}